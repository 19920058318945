import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom"; // Import Router components
import WetsuitDisplay from "./components/WetsuitDisplay";
import WetsuitDisplayGrid from "./components/WetsuitDisplayGrid";
import InputForm from "./components/InputForm";
import ButtonAppBar from "./components/ButtonAppBar";
import Grid2 from "@mui/material/Grid2";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import WelcomePage from "./components/Welcome";
import FitGuidePage from "./components/FitGuidePage";
import PartnerPage from "./components/PartnerPage";
import SiteMapPage from "./components/SiteMapPage";
import BrandsFilter from "./components/BrandsFilter";
import ViewBrands from "./components/ViewBrands";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import Widget from "./components/Widget";
import { useMediaQuery } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#444444",
    },
    secondary: {
      main: "#FFF",
    },
    background: {
      default: "#cccccc",
    },
  },
  typography: {
    h5: {
      color: "#ffffff",
      primary: "#ffffff",
    },
    subtitle1: {
      color: "#fff",
      secondary: "#fff",
      fontSize: "0.7rem",
    },
    subtitle2: {
      color: "#000",
      secondary: "#fff",
      fontSize: "0.7rem",
      inverse: "#fff",
    },
    body2: {
      color: "#000",
      secondary: "#fff",
      fontSize: "0.8rem",
    },
    h4: {
      color: "#000",
      secondary: "#fff",
      fontSize: "1.2rem",
    },
    h6: {
      color: "#000",
      secondary: "#fff",
      fontSize: "1.3rem",
    },
  },
});

const App: React.FC = () => {
  const [formData, setFormData] = useState({
    brand: "",
    height: 166,
    chest: 96,
    waist: 76,
    weight: 68,
    gender: "",
    country: "",
    isMetric: true,
  });

  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate(); // React router hook to navigate programmatically

  // Handle form submission
  const handleFormSubmit = (data: {
    brand: string;
    height: number;
    chest: number;
    waist: number;
    weight: number;
    gender: string;
    country: string;
    isMetric: boolean;
    hasBeenSubmitted: boolean;
  }) => {
    setFormData(data); // Update the state with form data
    navigate("/wetsuits"); // Navigate to the wetsuits page
  };

  // Handle "Get Started" button click
  const handleGetStarted = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hasBeenSubmitted: true,
    }));
    navigate("/wetsuits"); // Navigate to the wetsuits page
  };

  // Handle navigation clicks from the AppBar
  const handleNavClick = (page: string) => {
    navigate(page === "home" ? "/" : `/${page}`); // Navigate to the desired page
  };
  const [open, setOpen] = useState(() => {
    return !document.cookie
      .split("; ")
      .find((row) => row.startsWith("acceptcookies="));
  });

  const handleAccept = () => {
    setOpen(false);
    document.cookie = "acceptcookies=true; max-age=31536000; path=/";
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        message="We use cookies to improve your experience. By using our site, you agree to our use of cookies."
        action={
          <Button color="secondary" size="small" onClick={handleAccept}>
            Accept
          </Button>
        }
      ></Snackbar>
      <Routes>
        <Route path="/widget" element={<Widget />} />
        <Route
          path="*"
          element={
            <>
              <ButtonAppBar onClick={handleNavClick} />
              <div>
                <Grid2
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 6, sm: 12, md: 12 }}
                  direction={{ xs: "row", sm: "row" }}
                  sx={{ m: { xs: 1, sm: 1, md: 1 } }}
                >
                  <Grid2 size={{ xs: 6, sm: 3, md: 2 }}>
                    {isXsScreen === true ? (
                      <>
                        <InputForm
                          onFormSubmit={handleFormSubmit}
                          isSectionCollapsed={true}
                        />
                      </>
                    ) : (
                      <>
                        <InputForm
                          onFormSubmit={handleFormSubmit}
                          isSectionCollapsed={false}
                        />
                      </>
                    )}
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 9, md: 10 }}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <WelcomePage onGetStarted={handleGetStarted} />
                        }
                      />
                      <Route
                        path="/wetsuits"
                        element={<WetsuitDisplay formData={formData} />}
                      />
                      <Route
                        path="/viewbrands"
                        element={
                          <>
                            <BrandsFilter onClick={handleNavClick} />
                            <ViewBrands />
                          </>
                        }
                      />
                      <Route path="/fitguide" element={<FitGuidePage />} />
                      <Route path="/sitemap" element={<SiteMapPage />} />
                      <Route path="/partners" element={<PartnerPage />} />
                      <Route
                        path="/grid"
                        element={<WetsuitDisplayGrid formData={formData} />}
                      />
                    </Routes>
                  </Grid2>
                </Grid2>
              </div>
            </>
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
