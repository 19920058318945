import React from "react";
import { encode } from "html-entities";

// Define the structure for a sitemap entry
interface SitemapEntry {
  loc: string; // The URL of the page
  lastmod?: string; // Last modified date in YYYY-MM-DD format
  changefreq?:
    | "always"
    | "hourly"
    | "daily"
    | "weekly"
    | "monthly"
    | "yearly"
    | "never"; // Change frequency
  priority?: string; // Priority value between 0.0 and 1.0
}

// Sample data for the sitemap
const items = [
  { brand: "O'Neill", country: "United States", gender: "Men" },
  { brand: "O'Neill", country: "United States", gender: "Women" },
  { brand: "O'Neill", country: "United Kingdom", gender: "Men" },
  { brand: "O'Neill", country: "United Kingdom", gender: "Women" },
  { brand: "Rip Curl", country: "United States", gender: "Men" },
  { brand: "Rip Curl", country: "United States", gender: "Women" },
  { brand: "O'Neill", country: "Australia", gender: "Men" },
  { brand: "O'Neill", country: "Australia", gender: "Women" },
  { brand: "Rip Curl", country: "United Kingdom", gender: "Men" },
  { brand: "Rip Curl", country: "United Kingdom", gender: "Women" },
  { brand: "Billabong", country: "United States", gender: "Men" },
  { brand: "Billabong", country: "United States", gender: "Women" },
  { brand: "Billabong", country: "United Kingdom", gender: "Men" },
  { brand: "Billabong", country: "United States", gender: "Men" },
  { brand: "Billabong", country: "United States", gender: "Women" },
  { brand: "Vissla", country: "United States", gender: "Men" },
  { brand: "Vissla", country: "United Kingdom", gender: "Men" },
  { brand: "Quiksilver", country: "United States", gender: "Men" },
  { brand: "Roxy", country: "United States", gender: "Women" },
  { brand: "Quiksilver", country: "United Kingdom", gender: "Men" },
  { brand: "Roxy", country: "United Kingdom", gender: "Women" },
  { brand: "Patagonia", country: "United Kingdom", gender: "Men" },
  { brand: "Patagonia", country: "United Kingdom", gender: "Women" },
  { brand: "Patagonia", country: "United States", gender: "Men" },
  { brand: "Patagonia", country: "United States", gender: "Women" },
  { brand: "Body Glove", country: "United States", gender: "Men" },
  { brand: "Body Glove", country: "United States", gender: "Women" },
  { brand: "Alder", country: "United Kingdom", gender: "Men" },
  { brand: "Alder", country: "United Kingdom", gender: "Women" },
  { brand: "Volcom", country: "United States", gender: "Men" },
  { brand: "Volcom", country: "United States", gender: "Women" },
  { brand: "Dakine", country: "United States", gender: "Men" },
  { brand: "Buell", country: "United States", gender: "Men" },
  { brand: "Xcel", country: "United States", gender: "Men" },
  { brand: "Xcel", country: "United States", gender: "Women" },
  { brand: "Xcel", country: "United Kingdom", gender: "Men" },
  { brand: "Xcel", country: "United Kingdom", gender: "Women" },
  { brand: "Billabong", country: "Australia", gender: "Men" },
  { brand: "Billabong", country: "Australia", gender: "Women" },
  { brand: "Billabong", country: "United Kingdom", gender: "Women" },
  { brand: "Buell", country: "United States", gender: "Men" },
  { brand: "Buell", country: "United States", gender: "Men" },
  { brand: "Buell", country: "United States", gender: "Women" },
  { brand: "Buell", country: "Australia", gender: "Men" },
  { brand: "Buell", country: "Australia", gender: "Women" },
  { brand: "Patagonia", country: "Australia", gender: "Men" },
  { brand: "Patagonia", country: "Australia", gender: "Women" },
  { brand: "Quiksilver", country: "Australia", gender: "Men" },
  { brand: "Roxy", country: "Australia", gender: "Women" },
  { brand: "Xcel", country: "Australia", gender: "Men" },
  { brand: "Xcel", country: "Australia", gender: "Women" },
  { brand: "Volcom", country: "Australia", gender: "Men" },
  { brand: "Volcom", country: "Australia", gender: "Women" },
  { brand: "Volcom", country: "United Kingdom", gender: "Men" },
  { brand: "Volcom", country: "United Kingdom", gender: "Women" },
  { brand: "Vissla", country: "Australia", gender: "Men" },
  { brand: "Rip Curl", country: "Australia", gender: "Men" },
  { brand: "Rip Curl", country: "Australia", gender: "Women" },
  { brand: "Feral", country: "United States", gender: "Women" },
  { brand: "Feral", country: "United Kingdom", gender: "Women" },
  { brand: "Feral", country: "Australia", gender: "Women" },
  { brand: "Feral", country: "United States", gender: "Men" },
  { brand: "Feral", country: "United Kingdom", gender: "Men" },
  { brand: "Feral", country: "Australia", gender: "Men" },
];

// Dynamically generate sitemap entries based on the items list
const sitemapEntries: SitemapEntry[] = items.map((item) => ({
  loc: `https://wetsuitsizer.com/viewbrands?brand=${encodeURIComponent(
    item.brand
  )}&country=${encodeURIComponent(item.country)}&gender=${encodeURIComponent(
    item.gender
  )}`,
  lastmod: "2024-09-30", // You can dynamically set this based on your data
  changefreq: "weekly",
  priority: "0.8",
}));

sitemapEntries.push({
  loc: "https://example.com/fitGuide",
  lastmod: "2024-09-30",
  changefreq: "monthly",
  priority: "1.0",
});

const Sitemap: React.FC = () => {
  const xmlHeader = `<?xml version="1.0" encoding="UTF-8"?>`;
  const urlsetOpen = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`;
  const urlsetClose = `</urlset>`;

  const urlEntries = sitemapEntries
    .map((entry) => {
      return `
          <url>
            <loc>${entry.loc}</loc>
            ${entry.lastmod ? `<lastmod>${entry.lastmod}</lastmod>` : ""}
            ${
              entry.changefreq
                ? `<changefreq>${entry.changefreq}</changefreq>`
                : ""
            }
            ${entry.priority ? `<priority>${entry.priority}</priority>` : ""}
          </url>
        `;
    })
    .join("");

  const sitemapXml = `${xmlHeader}\n${urlsetOpen}\n${urlEntries}\n${urlsetClose}`;

  return <div dangerouslySetInnerHTML={{ __html: encode(sitemapXml) }} />;
};

export default Sitemap;
