import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../brand/logo.png";
import { Button, useMediaQuery, Drawer, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

type ButtonAppBarProps = {
  onClick: (page: string) => void;
};

export default function ButtonAppBar({ onClick }: ButtonAppBarProps) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{
        width: "50vw",
        height: "100%",
        backgroundColor: "#f5f5f5",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "30px",
            alignItems: "center",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Typography variant="h6">Menu</Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={toggleDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <MenuItem
          onClick={() => onClick("fitGuide")}
          style={{ paddingLeft: 0, backgroundColor: "transparent" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Fit Guide
        </MenuItem>
        <MenuItem
          onClick={() =>
            onClick("viewbrands?brand=All&country=All&gender=All&isMetric=true")
          }
          style={{ paddingLeft: 0, backgroundColor: "transparent" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          View all brands
        </MenuItem>
      </div>
    </div>
  );

  return (
    <AppBar
      color="secondary"
      position="static"
      sx={{
        border: "none",
        boxShadow: "none",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Toolbar color="secondary">
        <IconButton
          size="large"
          edge="start"
          color="secondary"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => onClick("home")} // Make logo clickable to go home
        >
          <img
            src={logo}
            alt="Brand Logo"
            style={{
              width: 60,
              height: "auto",
              objectFit: "contain",
              marginRight: "0px",
            }}
          />
        </IconButton>

        <div onClick={() => onClick("home")} style={{ cursor: "pointer" }}>
          <Typography variant="h6" align="left" style={{ fontWeight: "bold" }}>
            Wetsuit Sizer
          </Typography>
          <Typography variant="subtitle2" align="left">
            Find the perfect fit for your next adventure
          </Typography>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {isMobile ? (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ marginLeft: "auto" }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <>
              {/* <Button color="inherit" onClick={() => onClick("partners")}>
                <Typography variant="subtitle2" color="inherit">
                  Partners
                </Typography>
              </Button> */}
              <Button
                color="inherit"
                onClick={() =>
                  onClick(
                    "viewbrands?brand=All&country=All&gender=All&isMetric=true"
                  )
                }
              >
                <Typography variant="subtitle2" color="inherit">
                  View all brands
                </Typography>
              </Button>
              <Button color="inherit" onClick={() => onClick("fitguide")}>
                <Typography variant="subtitle2" color="inherit">
                  Fit Guide
                </Typography>
              </Button>
              {/* <Button color="inherit" onClick={() => onClick("partner")}>
          {/* <Typography variant="subtitle2" color="inherit">
            Partner with us
          </Typography>
          </Button>*/}
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
