import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";

interface UserFitData {
  height: number;
  chest: number;
  waist: number;
  weight: number;
  gender: string;
  country: string;
}

const ThisIsMyFitButton: React.FC<{
  productId: string;
  userFitData: UserFitData;
}> = ({ productId, userFitData }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fitStatus, setFitStatus] = useState<"Yes" | "No" | null>(null); // State to track "Yes" or "No"

  const handleClick = async (status: "Yes" | "No") => {
    setLoading(true);
    setSuccess(false);
    setFitStatus(status);

    try {
      const isDevelopment = process.env.NODE_ENV === "development";
      const API_URL: string | undefined = isDevelopment
        ? process.env.REACT_APP_SUBMISSIONS_API_DEV_URL
        : process.env.REACT_APP_SUBMISSIONS_API_PROD_URL;

      const url = `${API_URL}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_APIKEY || "",
        },
        body: JSON.stringify({
          product_id: productId,
          height: userFitData.height,
          chest: userFitData.chest,
          waist: userFitData.waist,
          weight: userFitData.weight,
          doesitfit: status,
          IPAddress: await getUserIpAddress(),
        }),
      });

      if (response.ok) {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error posting fit:", error);
    } finally {
      setLoading(false);
    }
  };

  const getUserIpAddress = async (): Promise<string> => {
    const apiUrl = process.env.REACT_APP_APIFY;
    if (!apiUrl) {
      throw new Error("REACT_APP_APIFY environment variable is not defined.");
    }
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data.ip;
  };

  return (
    <div>
      <IconButton
        onClick={() => handleClick("Yes")}
        color="primary"
        disabled={loading || success}
        title="This Fits!"
        sx={{
          borderRadius: "20%",
          border: "0px solid",
          padding: 1,
          marginRight: 1,
        }}
      >
        {loading && fitStatus === "Yes" ? (
          <CircularProgress size={20} />
        ) : (
          <span>
            <FontAwesomeIcon icon={faThumbsUp} size="sm" />
          </span>
        )}
      </IconButton>

      <IconButton
        onClick={() => handleClick("No")}
        color="primary"
        disabled={loading || success}
        title="This doesn't fit"
        sx={{
          borderRadius: "20%",
          border: "0px solid",
          padding: 1,
        }}
      >
        {loading && fitStatus === "No" ? (
          <CircularProgress size={20} />
        ) : (
          <span>
            <FontAwesomeIcon icon={faThumbsDown} size="sm" />
          </span>
        )}
      </IconButton>
    </div>
  );
};

export default ThisIsMyFitButton;
