import Grid2 from "@mui/material/Grid2"; // or import from Material-UI Grid2
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

interface MeasurementDisplayProps {
  label: string;
  min: number;
  max: number;
  currentValue: number;
  unit: string; // Can be "cm", "inches", "kg", or "lbs";
}

const MeasurementDisplay: React.FC<MeasurementDisplayProps> = ({
  label,
  min,
  max,
  currentValue,
  unit,
}) => {
  return (
    <Grid2
      columns={{ xs: 12, sm: 12, md: 12 }}
      container
      spacing={0}
      alignItems="center"
    >
      {/* Column 1: Label */}
      <Grid2 size={{ xs: 4, sm: 3, md: 4 }}>
        <Typography variant="body2" color="text.secondary">
          <strong>{label}:</strong>
        </Typography>
      </Grid2>

      {/* Column 2: Measurements */}
      <Grid2 size={{ xs: 1, sm: 1, md: 1 }} alignItems="left">
        <Typography variant="body2" color="text.secondary">
          {min}
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 1, sm: 1, md: 1 }} alignItems="left">
        <Typography variant="body2" color="text.secondary"></Typography>
      </Grid2>
      <Grid2 size={{ xs: 1, sm: 1, md: 1 }} alignItems="center">
        <Typography variant="body2" color="text.secondary">
          -
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 1, sm: 1, md: 1 }} alignItems="left">
        <Typography variant="body2" color="text.secondary">
          {max}
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 1, sm: 2, md: 2 }} alignItems="right">
        <Typography variant="body2" color="text.secondary" align="right">
          {unit}
        </Typography>
      </Grid2>

      {/* Column 3: Tick/Plus/Minus */}
      <Grid2 size={{ xs: 2, sm: 2, md: 2 }} style={{ textAlign: "right" }}>
        <FontAwesomeIcon
          icon={
            min === 0 && max === 0
              ? faTimesCircle // No entry icon when min and max are both zero
              : currentValue < min * 0.96 || currentValue > max * 1.04
              ? faCircle
              : currentValue >= min && currentValue <= max
              ? faCircle
              : faCircle
          }
          color={
            min === 0 && max === 0
              ? "grey" // Color for no entry icon
              : currentValue < min * 0.96 || currentValue > max * 1.04
              ? "red"
              : currentValue >= min && currentValue <= max
              ? "green"
              : "orange"
          }
        />
      </Grid2>
    </Grid2>
  );
};

export default MeasurementDisplay;
