import React, { useState, useEffect } from "react";
import { Container, Typography, CircularProgress, Box } from "@mui/material";

import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

// Conversion functions
const inchesToCm = (inches: number): number => Math.round(inches * 2.54);
const lbsToKg = (lbs: number): number => Math.round(lbs * 0.453592);

// Define types for the Wetsuit and User Measurements
interface Wetsuit {
  productID: number;
  brandID: number;
  genderID: number;
  countryID: number;
  brandName: string;
  type: string;
  genderName: string;
  heightMin: number;
  heightMax: number;
  weightMin: number;
  weightMax: number;
  chestMin: number;
  chestMax: number;
  waistMin: number;
  waistMax: number;
  sizeName: string;
  countryName: string;
  countryCode: string;
  score: number;
  affiliateLinks: string[];
  affiliateProgramNames: string[];
  affiliateProgramCodes: string[];
  affiliateCountries: string[];
}

interface UserMeasurements {
  height: number;
  chest: number;
  waist: number;
  weight: number;
  gender: string;
  country: string;
  isMetric: boolean;
  isSectionCollapsed?: boolean;
}

interface WetsuitDisplayGridProps {
  formData: UserMeasurements;
}

const WetsuitDisplayGrid: React.FC<WetsuitDisplayGridProps> = ({
  formData,
}) => {
  const [wetsuitData, setWetsuitData] = useState<Wetsuit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { isMetric } = formData;

  useEffect(() => {
    const fetchData = async () => {
      const metricHeight = isMetric
        ? formData.height
        : inchesToCm(formData.height);
      const metricChest = isMetric
        ? formData.chest
        : inchesToCm(formData.chest);
      const metricWaist = isMetric
        ? formData.waist
        : inchesToCm(formData.waist);
      const metricWeight = isMetric
        ? formData.weight
        : lbsToKg(formData.weight);

      setLoading(true);
      const API_URL = "";
      try {
        const isDevelopment = process.env.NODE_ENV === "development";
        let API_URL: string | undefined = isDevelopment
          ? process.env.REACT_APP_SUGGESTIONS_API_DEV_URL
          : process.env.REACT_APP_SUGGESTIONS_API_PROD_URL;

        if (!API_URL) {
          API_URL = "https://prod-wetsuitsizer.azurewebsites.net/suggestions";
        }
        const url = `${API_URL}?userHeight=${metricHeight}&userChest=${metricChest}&userWaist=${metricWaist}&userWeight=${metricWeight}&userGender=${formData.gender}&userCountry=${formData.country}`;
        const APIKEY = process.env.REACT_APP_APIKEY;

        const response = await axios.get(url, {
          headers: {
            "x-api-key": APIKEY,
          },
        });

        setWetsuitData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data." + err + API_URL);
        setLoading(false);
      }
    };

    fetchData();
  }, [
    formData.country,
    formData.gender,
    formData.height,
    formData.chest,
    formData.waist,
    formData.weight,
    isMetric,
  ]);

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress size={80} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      {wetsuitData.length === 0 ? (
        <Typography variant="h4" align="center" sx={{ mt: 4 }}>
          No wetsuits found. Please adjust your search criteria.
        </Typography>
      ) : (
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={wetsuitData.map((wetsuit, index) => ({
              ...wetsuit,
              id: index,
              brandName: wetsuit.brandName || "Unknown",
              type: wetsuit.type || "Unknown",
              genderName: wetsuit.genderName || "Unknown",
              heightRange:
                wetsuit.heightMin && wetsuit.heightMax
                  ? `${wetsuit.heightMin} - ${wetsuit.heightMax} ${
                      isMetric ? "cm" : "in"
                    }`
                  : "N/A",
              weightRange:
                wetsuit.weightMin && wetsuit.weightMax
                  ? `${wetsuit.weightMin} - ${wetsuit.weightMax} ${
                      isMetric ? "kg" : "lbs"
                    }`
                  : "N/A",
              chestRange: wetsuit.chestMin
                ? `${wetsuit.chestMin} - ${wetsuit.chestMax} ${
                    isMetric ? "cm" : "in"
                  }`
                : "N/A",
              waistRange: wetsuit.waistMin
                ? `${wetsuit.waistMin} - ${wetsuit.waistMax} ${
                    isMetric ? "cm" : "in"
                  }`
                : "N/A",
              sizeName: wetsuit.sizeName || "Unknown",
              score: wetsuit.score || 0,
              affiliateLinks: wetsuit.affiliateLinks || [],
              affiliateProgramNames: wetsuit.affiliateProgramNames || [],
            }))}
            columns={[
              {
                field: "brandName",
                headerName: "Brand",
                flex: 1,
                headerAlign: "center",
                align: "center",
              },
              {
                field: "sizeName",
                headerName: "Size",
                flex: 1,
                headerAlign: "center",
                align: "center",
              },
              {
                field: "heightRange",
                headerName: "Height Range",
                flex: 1,
                headerAlign: "center",
                align: "center",
              },
              {
                field: "weightRange",
                headerName: "Weight Range",
                flex: 1,
                headerAlign: "center",
                align: "center",
              },
              {
                field: "chestRange",
                headerName: "Chest Range",
                flex: 1,
                headerAlign: "center",
                align: "center",
              },
              {
                field: "waistRange",
                headerName: "Waist Range",
                flex: 1,
                headerAlign: "center",
                align: "center",
              },
              {
                field: "score",
                headerName: "Score",
                flex: 1,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => <strong>{params.value}</strong>,
              },
              {
                field: "affiliateLinks",
                headerName: "Affiliate Links",
                headerAlign: "center",
                align: "center",
                flex: 2,
                renderCell: (params) => (
                  <Box
                    textAlign={"center"}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="100%"
                  >
                    {params.row.affiliateLinks.length > 0 ? (
                      params.row.affiliateLinks.map(
                        (programLink: string, index: number) => (
                          <Typography variant="body2" key={index}>
                            <a
                              href={programLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Buy from{" "}
                              {params.row.affiliateProgramNames[index] ||
                                "Affiliate"}
                            </a>
                          </Typography>
                        )
                      )
                    ) : (
                      <Typography variant="body2">
                        No affiliate links
                      </Typography>
                    )}
                  </Box>
                ),
              },
            ]}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnResize
            disableColumnSorting
            disableColumnMenu
            disableColumnSelector
            columnHeaderHeight={30}
            rowHeight={25} // This reduces the row height
            sx={{
              "& .MuiDataGrid-cell": {
                padding: "0px 8px", // Adjust cell padding for a more compact look
                textAlign: "center", // Center align all cells
              },
              "& .MuiDataGrid-columnHeaders": {
                padding: "8px 8px", // Adjust header padding for consistency
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default WetsuitDisplayGrid;
