import React from "react";
import {
  Typography,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Container,
  TextField,
} from "@mui/material";

const PartnerPage: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContactSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission logic here, e.g., send data to backend or email
  };

  return (
    <>
      <Box
        sx={{
          overflowX: "hidden",
          padding: 0,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: "url('/partner-background.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.0rem",
                sm: "1.5rem",
                md: "2.0rem",
                lg: "3.0rem",
              },
              fontWeight: 700,
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
              marginBottom: "10px",
            }}
          >
            Partner with Wetsuit Sizer
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.8rem",
                sm: "1.2rem",
                md: "1.6rem",
                lg: "2.0rem",
              },
              fontWeight: 500,
              marginBottom: "0px",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            Integrate with your website or app
          </Typography>
        </Box>

        <Container maxWidth="md" sx={{ padding: 2, marginTop: 1 }}>
          <Typography variant="body1" gutterBottom>
            Enhance your customers' experience by integrating our advanced
            Wetsuit Sizer technology into your website or app. Offer accurate
            sizing recommendations and reduce returns due to incorrect sizing.
          </Typography>

          {/* <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
            Subscription Options
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Card sx={{ minHeight: "100%" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    API Access
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Integrate our Wetsuit Sizer API into your backend systems.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - Up to 5,000 API calls per month
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - Single website integration
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - Additional calls and sites available at extra cost
                  </Typography>
                  <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
                    $99/month
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant="contained" color="primary">
                    Request API Access
                  </Button>
                </CardActions>
              </Card>
            </Grid> */}

          {/* <Grid item xs={12} sm={6}>
              <Card sx={{ minHeight: "100%" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Web Widget
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Embed our customizable widget directly into your site.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - Unlimited usage
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - Single website integration
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - Priority support
                  </Typography>
                  <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
                    $199/month
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant="contained" color="primary">
                    Request Widget Access
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid> */}

          {/* <Typography variant="body1" gutterBottom sx={{ marginTop: 4 }}>
            For multiple websites or custom solutions, please contact us for a
            tailored plan.
          </Typography> */}

          {/* <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
            Terms and Conditions
          </Typography>
          <Typography variant="body2" gutterBottom>
            By subscribing to our services, you agree to the following terms:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2">
                Subscriptions are billed monthly and can be canceled at any time
                with a 30-day notice.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                API usage is limited to the specified number of calls per month.
                Additional calls will incur extra charges.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Access is granted for one website domain. Additional domains
                require separate subscriptions or an enterprise plan.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Unauthorized sharing or redistribution of our API or widget is
                prohibited.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                We reserve the right to modify the terms and pricing with a
                30-day notice.
              </Typography>
            </li>
          </ul>

          <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
            Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            Interested in partnering with us? Fill out the form below, and we'll
            get back to you shortly.
          </Typography> */}

          <Box component="form" onSubmit={handleContactSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth label="Name" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Company"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Phone"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Message"
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size={isSmallScreen ? "medium" : "large"}
                  sx={{
                    background: "linear-gradient(to right, #0077b6, #00b4d8)",
                    color: "#000",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      background: "linear-gradient(to right, #00b4d8, #0077b6)",
                    },
                  }}
                >
                  Submit Request
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PartnerPage;
