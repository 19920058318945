import React from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const FitGuidePage: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          overflowX: "hidden",
          padding: 0,
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "0px 0px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "1.8rem",
                sm: "2.4rem",
                md: "3rem",
              },
              fontWeight: 700,
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
              marginBottom: "0px",
              color: "#000",
            }}
          >
            Wetsuit Fit Guide
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.4rem",
                md: "1.8rem",
              },
              fontWeight: 400,
              color: "#000",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
              marginBottom: "0px",
            }}
          >
            Find your perfect wetsuit size with our guide
          </Typography>
        </Box>

        <Container maxWidth="md" sx={{ padding: "40px 20px" }}>
          <Typography variant="body1" gutterBottom>
            Wetsuit Sizer is designed to help you create a shortlist of wetsuits
            based on your measurements. Always try on any wetsuit before
            purchasing to ensure the best fit.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ marginTop: "20px" }}>
            How to measure yourself
          </Typography>
          <Divider sx={{ marginBottom: "20px" }} />
          <Typography variant="body1" gutterBottom>
            Accurate measurements are essential for finding a wetsuit that fits
            perfectly. Height and Weight are the most important measurements,
            but including Chest and Waist sizes will further improve the fit.
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Height"
                secondary="Stand straight against a wall without shoes. Measure from the floor to the top of your head."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Chest"
                secondary="Measure around the fullest part of your chest, keeping the tape measure level and under your arms."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Waist"
                secondary="Measure around the narrowest part of your waist, usually just above the belly button."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Weight"
                secondary="Weigh yourself on a scale to ensure accurate sizing."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom sx={{ marginTop: "20px" }}>
            Choosing the Right Fit
          </Typography>
          <Divider sx={{ marginBottom: "20px" }} />
          <Typography variant="body1" gutterBottom>
            A properly fitting wetsuit should be snug but not restrictive. Here
            are some tips to ensure the right fit:
          </Typography>

          <List>
            <ListItem>
              <ListItemText
                primary="Snug Fit"
                secondary="The wetsuit should fit tightly against your body without gaps or wrinkles. It will loosen up when wet, so don't be discouraged if it feels tight when dry."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Mobility"
                secondary="You should be able to move your arms and legs freely without excessive resistance. Thicker wetsuits will restrict movement more, so ensure you're choosing the right thickness for your water conditions."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Neck and Cuffs"
                secondary="Check that the neck, wrist, and ankle openings are snug to prevent water flushing."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Length"
                secondary="The suit should cover your wrists and ankles without bunching. A suit that is slightly too short is better than one that's too long to avoid bunching at the knees and elbows."
              />
            </ListItem>
          </List>

          <Box sx={{ marginTop: 4, textAlign: "center" }}>
            <Button variant="outlined">Back to Top</Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FitGuidePage;
