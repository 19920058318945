import React, { useState } from "react";
import { Typography, Grid, Box, Container, Button, Modal } from "@mui/material";
import Widget from "./Widget";

// Manually import images
const images = [
  require("../logos/alder.png"),
  require("../logos/billabong.png"),
  require("../logos/bodyglove.png"),
  require("../logos/buell.png"),
  require("../logos/cskins.png"),
  require("../logos/dakine.png"),
  require("../logos/feral.png"),
  require("../logos/mystic.png"),
  require("../logos/oceanearth.png"),
  require("../logos/oneill.png"),
  require("../logos/patagonia.png"),
  require("../logos/quiksilver.png"),
  require("../logos/ripcurl.png"),
  require("../logos/roxy.png"),
  require("../logos/sola.png"),
  require("../logos/vissla.png"),
  require("../logos/volcom.png"),
  require("../logos/xcel.png"),
  // Add more images as needed
];

interface WelcomeProps {
  onGetStarted: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ onGetStarted }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          overflowX: "hidden", // Prevent horizontal scrolling
          padding: 0, // Remove padding to prevent overflow
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.0rem",
                sm: "1.5rem",
                md: "2.0rem",
                lg: "3.0rem",
              },
              fontWeight: 700,
              color: "#000",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
              marginBottom: "10px",
            }}
          >
            Welcome to Wetsuit Sizer
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.0rem",
                sm: "1.5rem",
                md: "2.0rem",
                lg: "2.5rem",
              },
              fontWeight: 700,
              color: "#000",
              marginBottom: "20px",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            Find the perfect fit for your next adventure
          </Typography>
        </Box>
        <Box>
          <Button variant="outlined" onClick={onGetStarted}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.8rem",
                  sm: "1.2rem",
                  md: "1.6rem",
                  lg: "2.0rem",
                },
                fontWeight: 500,
                marginBottom: "0px",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
              }}
            >
              Find your perfect fit
            </Typography>
          </Button>
        </Box>
        <Container maxWidth="lg" sx={{ padding: 2, marginTop: 4 }}>
          <Grid container spacing={2} justifyContent="center">
            {images.map((image, index) => (
              <Grid item xs={6} sm={4} md={2} key={index}>
                <Box
                  component="img"
                  src={image}
                  alt={`logo-${index}`}
                  sx={{
                    width: "100%",
                    maxWidth: "100px",
                    height: "auto",
                    filter: "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2))",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
        <>
          {/* Existing code */}
          {/* <Widget /> */}
        </>
      </Box>
    </>
  );
};

export default Welcome;
