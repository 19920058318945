import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Fade,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Divider,
  Box,
  CardHeader,
  Avatar,
  Slider,
  Modal,
} from "@mui/material";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

interface Wetsuit {
  productID: number;
  brandID: number;
  genderID: number;
  countryID: number;
  brandName: string;
  type: string;
  genderName: string;
  heightMin: number;
  heightMax: number;
  weightMin: number;
  weightMax: number;
  chestMin: number;
  chestMax: number;
  waistMin: number;
  waistMax: number;
  sizeName: string;
  countryName: string;
  countryCode: string;
  score: number;
  affiliateLinks: string[];
  affiliateProgramNames: string[];
  affiliateProgramCodes: string[];
  affiliateCountries: string[];
}

const sanitizeBrandName = (brandName: string): string => {
  return brandName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};

const getLogoSrc = (brandName: string): string => {
  const sanitizedBrand = sanitizeBrandName(brandName);

  try {
    return require(`../logos/${sanitizedBrand}.png`);
  } catch (error) {
    return require("../logos/smwlogo.png");
  }
};

const cmToInches = (cm: number): number => Math.round(cm * 0.393701);
const inchesToCm = (inches: number): number => Math.round(inches * 2.54);
const kgToLbs = (kg: number): number => Math.round(kg * 2.20462);
const lbsToKg = (lbs: number): number => Math.round(lbs * 0.453592);

const Widget: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isMetric, setIsMetric] = useState(true);
  const [formData, setFormData] = useState({
    height: isMetric ? 166 : cmToInches(166),
    weight: isMetric ? 68 : kgToLbs(68),
    chest: isMetric ? 96 : cmToInches(96),
    waist: isMetric ? 76 : cmToInches(76),
    gender: "men",
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [wetsuitSuggestions, setWetsuitSuggestions] = useState<Wetsuit[]>([]);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    handleSubmit(formData.gender);
  }, [formData.gender]);

  const handleGenderChange = (newGender: string) => {
    // Set gender if different, but always submit
    if (formData.gender !== newGender) {
      setFormData((prevData) => ({ ...prevData, gender: newGender }));
    } else {
      handleSubmit(newGender); // Call handleSubmit if gender hasn't changed
    }
  };

  const handleSubmit = async (gender: string) => {
    // Use gender instead of formData.gender in the URL
    try {
      const isDevelopment = process.env.NODE_ENV === "development";
      let API_URL: string | undefined = isDevelopment
        ? process.env.REACT_APP_SUGGESTIONS_API_DEV_URL
        : process.env.REACT_APP_SUGGESTIONS_API_PROD_URL;

      if (!API_URL) {
        API_URL = "https://prod-wetsuitsizer.azurewebsites.net/suggestions";
      }

      const APIKEY = process.env.REACT_APP_APIKEY;
      const { height, weight, chest, waist } = formData;
      const metricHeight = isMetric
        ? formData.height
        : inchesToCm(formData.height);
      const metricWeight = isMetric
        ? formData.weight
        : lbsToKg(formData.weight);
      const metricChest = isMetric
        ? formData.chest
        : inchesToCm(formData.chest);
      const metricWaist = isMetric
        ? formData.waist
        : inchesToCm(formData.waist);

      const url = `${API_URL}?userHeight=${metricHeight}&userChest=${metricChest}&userWaist=${metricWaist}&userWeight=${metricWeight}&userGender=${gender}`;

      const response = await axios.get(url, {
        headers: {
          "x-api-key": APIKEY,
        },
      });

      setWetsuitSuggestions(response.data);
      setLoading(false);
      handleNext();
    } catch (error) {
      <Typography>There was an error fetching the data</Typography>;
    }
  };

  return (
    <Box
      sx={{
        overflowX: "hidden", // Prevent horizontal scrolling
        padding: 0, // Remove padding to prevent overflow
        textAlign: "center",
      }}
    >
      <Box>
        <Button variant="outlined" onClick={handleOpen}>
          Load Widget
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "95%", // 100% width on mobile
                md: 500, // 500px width on larger screens
              },
              height: "350", // 100% height on mobile
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              pt: 2,
              pb: 2,
              pl: 1,
              pr: 1,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <Typography variant="h6" color="black">
                X
              </Typography>
            </Box>
            {loading && (
              <Container sx={{ textAlign: "center", mt: 10 }}>
                <CircularProgress size={80} />
              </Container>
            )}
            <Typography variant="h4" align="center">
              Wetsuit Sizer
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Fade in={activeStep === 0}>
              <div style={{ display: activeStep === 0 ? "block" : "none" }}>
                <Typography variant="h6" align="center">
                  Choose your measurements
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant={isMetric ? "contained" : "outlined"}
                    onClick={() => {
                      if (!isMetric) {
                        setFormData({
                          ...formData,
                          height: inchesToCm(formData.height),
                          weight: lbsToKg(formData.weight),
                          chest: inchesToCm(formData.chest),
                          waist: inchesToCm(formData.waist),
                        });
                      }
                      setIsMetric(true);
                      handleNext();
                    }}
                    sx={{ mr: 2 }}
                  >
                    Metric
                  </Button>
                  <Button
                    variant={!isMetric ? "contained" : "outlined"}
                    onClick={() => {
                      if (isMetric) {
                        setFormData({
                          ...formData,
                          height: cmToInches(formData.height),
                          weight: kgToLbs(formData.weight),
                          chest: cmToInches(formData.chest),
                          waist: cmToInches(formData.waist),
                        });
                      }
                      setIsMetric(false);
                      handleNext();
                    }}
                  >
                    Imperial
                  </Button>
                </Box>
              </div>
            </Fade>
            <Fade in={activeStep === 1}>
              <div style={{ display: activeStep === 1 ? "block" : "none" }}>
                <Typography variant="h6">Enter your height:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Slider
                    value={formData.height}
                    onChange={(_, newValue) =>
                      setFormData({ ...formData, height: newValue as number })
                    }
                    min={isMetric ? 100 : 39}
                    max={isMetric ? 220 : 86}
                    valueLabelDisplay="auto"
                  />
                  <TextField
                    name="height"
                    value={formData.height}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        height: Number(e.target.value),
                      })
                    }
                    label={`Height (${isMetric ? "cm" : "inches"})`}
                  />
                </Box>
              </div>
            </Fade>
            <Fade in={activeStep === 2}>
              <div style={{ display: activeStep === 2 ? "block" : "none" }}>
                <Typography variant="h6">Enter your weight:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Slider
                    value={formData.weight}
                    onChange={(_, newValue) =>
                      setFormData({ ...formData, weight: newValue as number })
                    }
                    min={isMetric ? 30 : 66}
                    max={isMetric ? 122 : 369}
                    valueLabelDisplay="auto"
                  />
                  <TextField
                    value={formData.weight}
                    size="small"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        weight: Number(e.target.value),
                      })
                    }
                    label={`Weight (${isMetric ? "kg" : "lbs"})`}
                    sx={{ ml: 2 }}
                    inputProps={{
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Box>
              </div>
            </Fade>
            <Fade in={activeStep === 3}>
              <div style={{ display: activeStep === 3 ? "block" : "none" }}>
                <Typography variant="h6">Enter your chest size:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Slider
                    value={formData.chest}
                    onChange={(_, newValue) =>
                      setFormData({ ...formData, chest: newValue as number })
                    }
                    min={isMetric ? 50 : 20}
                    max={isMetric ? 150 : 60}
                    valueLabelDisplay="auto"
                  />
                  <TextField
                    value={formData.chest}
                    size="small"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        chest: Number(e.target.value),
                      })
                    }
                    label={`Chest (${isMetric ? "cm" : "inches"})`}
                    sx={{ ml: 2 }}
                    inputProps={{
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Box>
              </div>
            </Fade>
            <Fade in={activeStep === 4}>
              <div style={{ display: activeStep === 4 ? "block" : "none" }}>
                <Typography variant="h6">Enter your waist size:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Slider
                    value={formData.waist}
                    onChange={(_, newValue) =>
                      setFormData({ ...formData, waist: newValue as number })
                    }
                    min={isMetric ? 50 : 20}
                    max={isMetric ? 150 : 60}
                    valueLabelDisplay="auto"
                  />
                  <TextField
                    value={formData.waist}
                    size="small"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        waist: Number(e.target.value),
                      })
                    }
                    label={`Waist (${isMetric ? "cm" : "inches"})`}
                    sx={{ ml: 2 }}
                    inputProps={{
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Box>
              </div>
            </Fade>
            <Fade in={activeStep === 5}>
              <div style={{ display: activeStep === 5 ? "block" : "none" }}>
                <Typography variant="h6" align="center">
                  Choose the gender of the wetsuit you require
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant={
                      formData.gender === "men" ? "contained" : "outlined"
                    }
                    onClick={() => handleGenderChange("men")}
                    sx={{ mr: 2 }}
                  >
                    Male
                  </Button>
                  <Button
                    variant={
                      formData.gender === "women" ? "contained" : "outlined"
                    }
                    onClick={() => handleGenderChange("women")}
                    sx={{ mr: 2 }}
                  >
                    Female
                  </Button>
                </Box>
              </div>
            </Fade>
            {activeStep === 6 && (
              <div>
                <Carousel
                  responsive={responsive}
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={true}
                  className=""
                  containerClass="container"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {wetsuitSuggestions.length > 0 ? (
                    wetsuitSuggestions.map((wetsuit, index) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        key={index}
                      >
                        <Card
                          raised={false}
                          sx={{
                            border: "1px solid black",
                            boxShadow: "none",
                            height: "auto",
                            width: "200px",
                            padding: "10px",
                            borderRadius: "16px",
                          }}
                        >
                          <CardHeader
                            sx={{
                              display: "flex",
                              backgroundColor: "#fff",
                              "& .MuiCardHeader-content": {
                                overflow: "hidden",
                              },
                              paddingTop: "5px ",
                              paddingBottom: "0px",
                            }}
                            title={wetsuit.brandName}
                            titleTypographyProps={{
                              noWrap: true,
                              variant: "h6",
                            }}
                            // subheader={`Recommended Size: ${wetsuit.sizeName}`}
                            subheaderTypographyProps={{
                              noWrap: true,
                              variant: "subtitle2",
                              fontWeight: "bold",
                            }}
                            avatar={
                              <Avatar
                                sx={{
                                  width: 50,
                                  height: 50,
                                  color: "white",
                                  borderRadius: 0,
                                }}
                                alt="Brand Logo"
                                src={getLogoSrc(wetsuit.brandName)}
                              />
                            }
                            action={
                              <Avatar
                                sx={{
                                  bgcolor: "white",
                                  border: "1px solid white",
                                  fontSize: "1.0rem",
                                }}
                              >
                                {`${index + 1}\n/\n${
                                  wetsuitSuggestions.length
                                }`}
                              </Avatar>
                            }
                          />
                          <CardContent>
                            <Box>
                              <Gauge
                                width={160}
                                height={160}
                                value={wetsuit.score}
                                startAngle={-110}
                                endAngle={110}
                                sx={(theme) => ({
                                  [`& .${gaugeClasses.valueText}`]: {
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: 20,
                                    transform: "translate(0px, -10px)",
                                    "& tspan:last-of-type": {
                                      fontSize: 30,
                                    },
                                  },
                                  [`& .${gaugeClasses.valueArc}`]: {
                                    fill: "#52b202", // Customize the value arc
                                  },
                                  [`& .${gaugeClasses.referenceArc}`]: {
                                    fill: "grey", // Customize the reference arc
                                  },
                                })}
                                text={() => `Size \n ${wetsuit.sizeName}`} // \n adds line breaks
                              />
                            </Box>
                            {/* <Divider sx={{ my: 2 }} />
                              <Typography variant="h6" fontWeight={"bold"}>
                                Choice: {index + 1}
                              </Typography> */}
                            {/* {/* <div style={{ paddingLeft: "0px" }}>
                        <MeasurementDisplay
                          label="Height"
                          min={wetsuit.heightMin}
                          max={wetsuit.heightMin}
                          currentValue={wetsuit.heightMin}
                          unit={true ? "cm" : "inches"}
                        />

                        <MeasurementDisplay
                          label="Weight"
                          min={wetsuit.weightMin}
                          max={wetsuit.weightMax}
                          currentValue={wetsuit.weightMin}
                          unit={true ? "kg" : "lbs"}
                        />

                        <MeasurementDisplay
                          label="Chest"
                          min={wetsuit.chestMin}
                          max={wetsuit.chestMax}
                          currentValue={wetsuit.chestMin}
                          unit={"cm"}
                        />

                        <MeasurementDisplay
                          label="Waist"
                          min={wetsuit.waistMin}
                          max={wetsuit.waistMax}
                          currentValue={wetsuit.waistMin}
                          unit={"cm"}
                        />
                      </div> */}
                          </CardContent>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <Typography>No wetsuits found.</Typography>
                  )}
                </Carousel>
              </div>
            )}
            <Divider sx={{ my: 2 }} />
            {activeStep !== 1 && (
              <Button variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            {activeStep !== 6 && (
              <Button variant="outlined" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Widget;
